<template>
  <v-row>
    <v-col
      cols="12"
      xl="6"
      lg="6"
      md="6"
      sm="6"
      v-for="(menu, index) in menuArray"
      :key="index"
      v-if="menu.active_submenus.length > 0"
      class=""
      
    >
      <div class="pa-4 menu_container_col">
        <v-row class="">
          <v-col cols="12" class="menu_name pb-0"> {{ menu.name }} </v-col>
          <v-col cols="12" class="pt-0">
            <v-row>
              <v-col cols="4" xl="5" lg="5" md="4" sm="4" >
                <v-img
                  :src="menuIcons[menu.key]"
                  max-width="150px"
                  class="mx-auto "
                ></v-img>
              </v-col>
              <v-col
                cols="8"
                xl="7"
                lg="7"
                md="8"
                sm="8"
                class="submenu_container_col"
              >
                <div
                  v-for="(submenu, index) in menu.active_submenus"
                  :key="index"
                  class="submenu_name"
                  @click="submenuHandler(menu, submenu)"
                >
                  <v-icon size="16px" color="#29C944" class="submenu_icon">fas fa-caret-right</v-icon> <span>{{ submenu.name }}</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <div class="hr_line"></div>
    </v-col>
  </v-row>
</template>

<script>
import about from '../../assets/menu/About-us.png'
import academic from '../../assets/menu/Academic-Info.png'
import admission from '../../assets/menu/Admission.png'
import student from '../../assets/menu/Student-Corner.png'
import facility from '../../assets/menu/Facilities.png'
import result from '../../assets/menu/Result.png'
import gallery from '../../assets/menu/Gallery.png'
import curriculam from '../../assets/menu/Co-curriculam.png'
export default {
  beforeMount() {},
  components: {},
  computed: {},
  created(){
  },
  data() {
    return {
      menuIcons: {
        about,
        academic ,
        student ,
        facility ,
        result ,
        admission ,
        gallery ,
        curriculam ,
      },
      iconUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/Home_Icon_by_Lakas.svg/1200px-Home_Icon_by_Lakas.svg.png"
    };
  },
  methods: {
    submenuHandler(menu = null, submenu = null) {
      let link = null;
      if (menu.key == "home") {
        link = "/";
      }

      if (submenu != null) {
        if (submenu.submenuType == "co_curriculum") {
          link = "/curriculum/" + submenu.key;
        }

        if (submenu.submenuType == "details") {
          link = "/details/" + submenu.key;
        }
        if (submenu.submenuType == "information") {
          link = "/information/" + submenu.key;
        }

        if (submenu.submenuType == "facility") {
          link = "/facility/" + submenu.key;
        }

        if (submenu.submenuType == "home") {
          link = "/home/" + submenu.key;
        }

        if (submenu.submenuType == "pdf") {
          link = "/notice/" + submenu.key;
        }

        if (submenu.submenuType == "table") {
          link = "/" + submenu.key;
        }

        if (submenu.submenuType == "activity") {
          link = "/events?type=" + submenu.key;
        }

        if (submenu.key == "events") {
          link = "/" + submenu.key;
        }
      }

      if (link != null) {
        this.$router.push(link);
        this.selectedMenu = menu.key;
      }
    },
  },
  mounted() {},
  props: {
    menuArray: {
      type: Array,
      default: () => [],
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>
.menu_container_col {
  height: 100%;
  /* background-color: #c4c4c4; */
  border: 1px solid #b9afaf;
  /* background-color: rgb(235, 229, 229); */
  background-color: #F5F5F5;
  box-shadow: 1px 1px 0px 1px #b9afaf;
  /* box-shadow: 1px 1px 3px 3px #b3b2b2; */
}



.menu_name {
  font-size: 21px;
  line-height: 30px;
  /* font-weight: 600; */
  letter-spacing: 1px;
  color: #181818;
}
.submenu_name {
  font-size: .1.2;
  font-weight: 500;
  /* color: rgba(0, 0, 0, .6); */
  color: #666;
  cursor: pointer;

}

.submenu_name:hover span {
  color: #0f0333;
  text-decoration: underline;

}

.hr_line{
  width: 60%;
  height: 5px;
  margin: 0px auto;
  margin-top: 32px;
  background-color: rgba(47, 20, 65, 0.473);
  border-radius: 5px;
  display: none;
}

@media only screen and (max-width: 599px) {
  .hr_line{
    display: block;
  }
  .menu_container_col {
    padding: 4px;
    height: auto;
  }
  .submenu_container_col {
    padding: 4px !important;
  }
  .menu_name {
    font-size: 20px;
  }
 .submenu_name {
   font-size: 16px;
  }

}
@media only screen and (max-width: 500px) {
  .menu_name {
    letter-spacing: 0.5px;
  }

}
</style>
