<template>
  <v-row>
    <v-col cols="12">
      <skeleton-loader v-if="loadingState" />
      <div v-else>
        <h1 class="text-center mb-4 py-1" style="background-color: #bbdefb">
          {{ this.submenuName }}
        </h1>
        <div v-if="description">
          <description :description="description" />
        </div>
        <div v-else class="text-center">
          <no-data />
        </div></div
    ></v-col>
  </v-row>
</template>

<script>
import SkeletonLoader from "../components/loader/skeletonLoader.vue";

import Description from "../components/description.vue";
import * as api from "../config/api.js";
import NoData from "../components/noData.vue";
export default {
  beforeMount() {
    this.getData();
  },
  components: { SkeletonLoader, Description, NoData },
  computed: {},
  data() {
    return {
      loadingState: false,
      picPath: null,
      description: null,
      id: this.$route.params.id,
      submenuName: null,
    };
  },
  methods: {
    reset() {
      this.description = null;
      this.submenuName = null;
      this.loadingState = false;
    },
    getData() {
      this.reset();
      this.loadingState = true;
      this.$http
        .get(api.INFORMATION + "/" + this.id)
        .then((res) => {
          if (res.data) {
            this.description = res.data.description;
            this.submenuName = res.data.submenuName;
          }
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {
    "$route.params"() {
      this.id = this.$route.params.id;
      this.getData();
    },
  },
};
</script>

<style scoped>
.briefDescription {
  font-size: 20px;
  word-spacing: 2px;
  word-break: break-all;
  text-align: justify;
}
</style>
