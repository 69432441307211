<template>
  <v-row class=" ">
    <v-col
      class="pt-0 mb-2"
      cols="12"
      xl="12"
      lg="12"
      md="12"
      sm="6"
      v-for="(speech, index) in speechArray"
      :key="index"
      @click="speechHandler(speech.id)"
    >
      <div class="speech speeches_container">
        <div class="pa-4" style="height: 100%">
          <div class="speech_headline">{{ speech.headline }}</div>
          <div>
            <v-img :src="speech.picPath" width="100%" height="auto"></v-img>
          </div>
          <div class="speech_name">{{ speech.name }}</div>
          <div class="speech_name" style="color:#3F51B5">(see more)</div>
          <!-- <div class="text-right"> -->
            <!-- <v-icon class="speech_icon">fa-arrow-circle-right</v-icon> -->
            <!-- <v-icon>fa-angle-double-right</v-icon> -->
            <!-- <v-icon>fa-angle-right</v-icon> -->
          <!-- </div> -->
        </div>

        <div class="hr_line"></div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {
    speechHandler(id) {
      this.$router.push({ path: "/speech", query: { mid: id } });
    },
  },
  mounted() {},
  props: {
    speechArray: {
      type: Array,
      default: () => [],
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>
.speeches_container {
  background-color: #4e4e4e1a;
}

.speech:hover {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.speech_icon {
  color: white;
}
.speech:hover .speech_icon {
  color: #2e77ff;
}
.speech_headline {
  border: 1px solid black;
  color: white;
  font-size: 1.2rem;
  padding: 5px 10px;
  background-color: #969191;
  text-align: center;
}

.speech_name {
  font-size: 0.9rem;
  font-weight: 600;
  word-spacing: 2px;
  letter-spacing: 0.01rem;
  line-height: 1.2rem;
  margin: 7px 0px;
  text-align: center;
  color: rgb(74, 76, 87);
}

.hr_line {
  width: 80%;
  margin: auto;
  height: 4px;
  background-color: #969191;
  border-radius: 3px;
}
</style>
