import {HOST} from './apiHost.js'

const PREFIX = "api/front";
const BASE_URL = `${HOST}/${PREFIX}`;

//Homepage
export const HEADER_DATA = `${BASE_URL}/menu`;
export const HOME = `${BASE_URL}/home`;
//Counter
export const VISITOR_COUNTER = `${BASE_URL}/visitor/counter`;

// Notice
export const NOTICE = `${BASE_URL}/notice`; /* /submenuKey will be added */
export const VIEW_NOTICE = `${BASE_URL}/notice/view`; /* /notice id will be added */
export const DOWNLOAD_NOTICE = `${BASE_URL}/notice/download`; /* /notice id will be added */

// Details
export const DETAILS = `${BASE_URL}/details`; /* /submenuKey will be added */

// Facility
export const FACILITY = `${BASE_URL}/facility`; /* /submenuKey will be added */

// Co-curriculam
export const CO_CURRICULAM = `${BASE_URL}/curriculam`; /* /submenuKey will be added */

// Information
export const INFORMATION = `${BASE_URL}/information`; /* /submenuKey will be added */

// Speech
export const SPEECH = `${BASE_URL}/speech`; /* /speechId will be added */

// Teacher , Staff, Committe, Former Headmaster
export const TEACHER = `${BASE_URL}/teacher`;
export const STAFF = `${BASE_URL}/staff`;
export const COMMITTEE = `${BASE_URL}/committee`;
export const FORMER_HEADMATER = `${BASE_URL}/formerheadmaster`;

// Gallery
export const EVENTS = `${BASE_URL}/events`;
export const EVENT_DETAILS = `${BASE_URL}/events/details`;

export const GALLERY_IMAGES = `${BASE_URL}/gallery/images`;
export const GALLERY_VIDEOS = `${BASE_URL}/gallery/videos`;

//Contact

export const CONTACT = `${BASE_URL}/contact`;
