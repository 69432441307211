<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else>
      <div v-if="headline">
        <h2 style="background-color: white; padding: 10px; text-align: center">
          {{ headline }}
        </h2>
      </div>
      <div v-if="description">
        <description :description="description" />
      </div>
      <div>
        <v-row>
          <v-col cols="12" xl="3" lg="4" md="4" sm="6" v-for="(image, index) in images" :key="index">
            <image-modal :imgData="image" />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import ImageModal from "../components/modal/imageModal.vue";
import Description from "../components/description.vue";
export default {
  beforeMount() {},
  components: {
    ImageModal,
    Description,
  },
  computed: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  props: {
    loadingState: {
      type: Boolean,
      default: false,
    },
    headline: {
      type: String,
      default: null,
    },
    images: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: null,
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>

</style>
