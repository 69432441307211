<template>
  <v-row>
    <v-col cols="12">
      <skeleton-loader v-if="isComponentLoading" />
      <div v-else>
        <div v-if="!allNotice.length" class="noDataBanner">
          No Notice Available
        </div>
        <div v-else >
          <h1 class="text-center py-2 mb-6" style="background-color: #bbdefb">
            {{ this.submenuName }}
          </h1>

          <v-col cols="12">
            <pdf-notice-card
              v-for="(notice, index) in allNotice"
              :key="index"
              :noticeData="notice"
              :downloadButtonHandler="() => downloadNotice(notice)"
              
            />
            <div class="text-center" v-if="paginationLength > 1">
              <v-pagination
                v-model="page"
                :length="paginationLength"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
                @input="getAllNoticeData"
              ></v-pagination>
            </div>
          </v-col>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import * as api from "../config/api.js";
import PdfNoticeCard from "../components/card/pdfNoticeCard.vue";
import SkeletonLoader from "../components/loader/skeletonLoader.vue";
export default {
  beforeMount() {
    if (this.$route.query.page) {
      this.page = Number(this.$route.query.page);
    }
    this.getAllNoticeData();
  },
  components: {
    PdfNoticeCard,
    SkeletonLoader,
  },
  computed: {},
  data() {
    return {
      page: 1,
      paginationLength: 0,
      allNotice: [],
      isComponentLoading: false,
      key: this.$route.params.id,
      submenuName: null,
    };
  },
  methods: {
    getAllNoticeData() {
      this.isComponentLoading = true;
      this.$http
        .get(`${api.NOTICE}/${this.key}?page=${this.page}`)
        .then((res) => {
          this.allNotice = res.data.data.data;
          this.submenuName = res.data.submenu;
          this.paginationLength = res.data.data.last_page;
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.isComponentLoading = false;
        });
    },
    downloadNotice(notice) {
      let link = `${api.DOWNLOAD_NOTICE}/${notice.id}`;

      window.open(link);
      // this.$http
      //   .get(`${api.DOWNLOAD_NOTICE}/${notice.id}`)
      //   .then((res) => {

      //   })
      //   .catch((error) => {
      //     console.log(" Error=======>", error.response.data);
      //     if (
      //       error.response &&
      //       error.response.status &&
      //       error.response.status == 403
      //     ) {
      //       alert();
      //     }
      //   })
      //   .finally(() => {});
    },
  },
  mounted() {},
  props: {
    noticeApi: {
      default: null,
    },
  },
  updated() {},
  watch: {
    page() {
      this.$router.push({ query: { page: this.page } });
    },
    "$route.params"() {
      this.key = this.$route.params.id;
      this.getAllNoticeData();
    },
  },
};
</script>

<style scoped>
.noDataBanner {
  width: 100%;
  height: 200px;
  border: 1px solid rgba(4, 12, 95, 0.363);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 2px;
  word-spacing: 4px;
  background-color: white;
}

@media only screen and (max-width: 600px) {
  .noDataBanner {
    font-size: 25px;
  }
}
</style>
