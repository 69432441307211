export const HOME = "/";
export const DETAILS = "/details/:id";
export const ALL_NOTICE = "/notice/notices";
export const NOTICE = "/notice/:id";
export const VIEW_NOTICE = "/notice/view";

export const FACILITY = "/facility/:id";
export const CURRICULUM = "/curriculum/:id";
export const INFORMATION = "/information/:id";
export const SPEECH = "/speech";

export const TEACHER = "/teachers";
export const STAFF = "/staffs";
export const COMMITTEE = "/committee";
export const FORMER_HEADMATER = "/former_headmasters";

export const EVENTS = "/events";
export const EVENT_DETAILS = "/events/:id";
export const GALLERY_IMAGES = "/gallery/photos";
export const GALLERY_VIDEOS = "/gallery/videos";


export const CONTACT_US = "/contact";



