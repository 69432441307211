<template>
  <v-row>
    <v-col cols="12">
      <v-row v-if="loadingState">
        <v-col>
          <loader class="w-100" />
        </v-col>
      </v-row>
      <v-row v-else class="home_container">
        <!-- Marquee Notice -->
        <v-col cols="12" class="" v-if="recentNotices.length">
          <marquee-notice   :noticeArray="recentNotices" />
        </v-col>
        
        <!--  -->
        <!-- Left Column -->
        <!--  -->
        <v-col cols="12" xl="9" lg="9" md="9" sm="12" class="">
          <!-- Slider -->
          <v-row class="" v-if="sliderArray.length" >
            <v-col class="pt-0">
              <slider :sliderImageArray="sliderArray" class="w-100" />
            </v-col>
          </v-row>
          <!-- NoticeBoard -->
          <v-row class="" v-if="recentNotices.length">
            <v-col>
              <notice-board
                :isComponentLoading="loadingState"
                :notices="recentNotices"
                class=""
              />
            </v-col>
          </v-row>
          <!-- Menu and Submenu -->
          <menus :menuArray="menuArray" />
          <v-row>
            <br />
          </v-row>
          <!-- Youtube Class -->
          <v-row class="justify-content-center" v-if="classVideoArray.length">
            <v-col cols="12" class="pb-0">
              <div class="online_class_headline section_headline">
                Some of our Online Classes
              </div>
            </v-col>
            <v-col cols="12" class="">
              <videos :videos="classVideoArray" />
            </v-col>
          </v-row>
        </v-col>

        <!--  -->
        <!-- Right/Side Column -->
        <!--  -->
        <v-col cols="12" xl="3" lg="3" md="3" sm="12" class="">
          <!-- Speech -->
          <speech :speechArray="speechArray" v-if="speechArray.length" />

          <!-- Important Links -->
          <v-row v-if="importantLinks.length">
            <important-link
              class=""
              :isComponentLoading="loadingState"
              :importantLinks="importantLinks"
            />
          </v-row>

          <v-row>
            <v-col>
              <div class="important_links_board_headline section_headline">
                Hotline
              </div>
              <v-img
                class=""
                width="100%"
                src="../assets/hot_line.jpg"
                contain 
              ></v-img>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <visitor-counter />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import * as api from "../config/api.js";
import ImportantLink from "../components/board/importantLinksBoard.vue";
import NoticeBoard from "../components/board/noticeBoard.vue";
import Slider from "../components/slider/carousel.vue";
import Videos from "../components/home/youtubeVideos.vue";
import Menus from "../components/home/homeMenus.vue";
import VisitorCounter from "../components/home/visitorCounter.vue";
import Speech from "../components/home/speech.vue";
import MarqueeNotice from "../components/home/marqueeNotice.vue";

export default {
  beforeMount() {
    this.getHeaderData();
  },
  components: {
    ImportantLink,
    NoticeBoard,
    Slider,
    Videos,
    Menus,
    Speech,
    VisitorCounter,
    MarqueeNotice,
  },
  computed: {},
  data() {
    return {
      loadingState: false,
      menuArray: [],
      classVideoArray: [],
      sliderArray: [],
      speechArray: [],
      importantLinks: [],
      recentNotices: [],
    };
  },
  methods: {
    getHeaderData() {
      this.loadingState = true;
      this.$http
        .get(api.HOME)
        .then((res) => {
          this.menuArray = res.data.menu;
          this.speechArray = res.data.speech;
          this.sliderArray = res.data.slider;
          this.classVideoArray = res.data.classVideo;
          this.importantLinks = res.data.importantLinks;
          this.recentNotices = res.data.recentNotices;
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.card {
  background-color: #c4c4c4;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.online_class_headline {
  text-align: center;
  background-color: #c4c4c4;
  padding: 10px;
  /* font-size: 25px; */
  /* font-weight: 600; */
  /* letter-spacing: 1px; */
  color: black;
}

.important_links_board_headline {
  text-align: center;
  font-weight: 500;
  color: black;
  padding: 5px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid rgb(199, 201, 202);
  background-color: #969191;
  /* border-radius: 10px; */
}

@media only screen and (max-width:959px){
  .home_container{
    padding: 0 10px;
  }
}

</style>
