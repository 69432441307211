<template>
  <v-container>
    <br />
    <v-skeleton-loader
      v-if="isComponentLoading"
      type="image"
      height="400px"
    ></v-skeleton-loader>
    <div v-else>
      <h2 class="mb-4 text-center">
        {{ name }}
        <v-btn class="ml-4" @click="downloadNotice">
          <v-icon color="primary" size="18">fa-download</v-icon>
        </v-btn>
      </h2>
      <iframe
        :src="fileSrc + '#toolbar=0'"
        v-if="fileSrc"
        id="fred"
        style=""
        title="PDF in an i-Frame"
        frameborder="1"
        scrolling="auto"
        width="100%"
        :height="height"
      ></iframe>

      <div v-else class="text-center">
        <no-data />
      </div>
    </div>
  </v-container>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getPdfFileData();
  },
  components: {},
  computed: {},
  data() {
    return {
      isComponentLoading: false,
      fileSrc: null,
      height: screen.height + "px",
      name: null,
    };
  },
  methods: {
    downloadNotice(){
      let link = `${api.DOWNLOAD_NOTICE}/${this.$route.params.id}`;
      window.open(link);
    },
    fileHandler(file) {
      if (file) {
        let fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.addEventListener("load", () => {
          // this.fileSrc = fileReader.result;
          this.printPreview(fileReader.result);
        });
      }
    },
    printPreview(data) {
      this.fileSrc = URL.createObjectURL(
        this.pdfBlobConversion(data, "application/pdf")
      );
      // const blobURL = URL.createObjectURL(
      //   this.pdfBlobConversion(data, "application/pdf")
      // );
      // const theWindow = window.open(blobURL);
      // const theDoc = theWindow.document;
      // const theScript = document.createElement("script");
      // function injectThis() {
      //   window.print();
      // }
      // theScript.innerHTML = "window.onload = ${injectThis.toString()};";
      // theDoc.body.appendChild(theScript);
    },
    pdfBlobConversion(b64Data, contentType) {
      contentType = contentType || "";
      let sliceSize = 512;
      b64Data = b64Data.replace(/^[^,]+,/, "");
      b64Data = b64Data.replace(/\s/g, "");
      let byteCharacters = window.atob(b64Data);
      let byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset = offset + sliceSize
      ) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      let blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    getPdfFileData() {
      let noticeId = this.$route.params.id;
      this.isComponentLoading = true;
      this.$http
        .get(api.VIEW_NOTICE + "/" + noticeId)
        .then((res) => {
          this.fileSrc = res.data.url;
          this.name = res.data.name;
          // this.printPreview(res.data);
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.isComponentLoading = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
