<template>
  <v-row class="">
    <v-col cols="12" v-if="loadingState">
      <loader skeletonType="image" />
    </v-col>
    <v-col cols="12" class="" v-else>
      <v-row
        class="banner_color  mx-auto "
        style="position: relative; padding: 10px 0"
      >
        <v-img
          :src="schoolInformation.banner"
          width="100%"
          aspect-ratio="6"
          class="banner_img"
          style="
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
          "
          dark
        >
        </v-img>
        <div class="banner_img_backdrop"></div>
        <v-col cols="12" style="z-index: 10" class="">
          <v-row class="align-items-center">
            <v-col cols="3" xl="2" lg="2" md="2" class="logo">
              <v-img
                :src="schoolInformation.logo"
                max-width="100px"
                max-height="100px"
                width="100%"
                height="100%"
                class="mx-auto"
              >
              </v-img>
            </v-col>
            <v-col cols="9" xl="8" lg="8" md="8" class="info_container pa-2">
              <div class="name">{{ schoolInformation.name }}</div>
              <div class="name alternative_name">
                {{ schoolInformation.alternativeName }}
              </div>
              <div class="address">{{ schoolInformation.address }}</div>
              <div class="address">EIIN : {{ schoolInformation.eiin }}</div>
              <div>
                <!-- <v-img :src="schoolInfor"></v-img> -->
              </div>
            </v-col>
            <v-col
              cols="12"
              xl="2"
              lg="2"
              md="2"
              class="second_logo_container"
            >
              <a :href="schoolInformation.secondLogoUrl" target="_blank">
                <v-img
                  :src="schoolInformation.secondLogo"
                  max-height="150px"
                  max-width="150px"
                  height="100%"
                  width="100%"
                  contain
                  class=""
                ></v-img
              ></a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" class="py-0" style="z-index: 11">
          <Menu :menuData="menuData" />
        </v-col>
      </v-row>

      <v-btn
        @click="scrollToTop()"
        style="
          position: fixed;
          z-index: 2;
          right: 10px;
          bottom: 10px;
          opacity: 0.9;
        "
        color="primary"
        height="50px"
        width="50px"
        rounded
      >
        <v-icon>fa-angle-up</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import * as api from "../../config/api.js";
import Loader from "../loader/skeletonLoader.vue";
import Menu from "./menu.vue";
export default {
  beforeMount() {
    this.getHeaderData();
    this.userCounterTokenHandler();
  },
  components: {
    Loader,
    Menu,
  },
  data() {
    return {
      loadingState: false,
      schoolInformation: {},
      menuData: [],
    };
  },
  methods: {
    getHeaderData() {
      this.loadingState = true;
      this.$http
        .get(api.HEADER_DATA)
        .then((res) => {
          this.menuData = res.data.menu;
          this.schoolInformation = res.data.schoolInformation;

          this.headerHandler(this.schoolInformation);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    headerHandler(information) {
      document.title = information.name ? information.name : "school";

      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/png";
      link.rel = "shortcut icon";
      link.href = information.logo ? information.logo : null;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    userCounterTokenHandler() {
      let userToken = localStorage.getItem("userToken")
        ? JSON.parse(localStorage.getItem("userToken"))
        : null;

      if (!userToken) {
        userToken = new Date();
        localStorage.setItem("userToken", JSON.stringify(userToken));
      }

      let currentTime = new Date();

      /* 5 min, 1sec = 1000 mili sec  */
      let minimumTimeForCreatingNewToken = 1000 * 60 * 5;

      /* checking the difference between current time and the last time the token been genereted  */
      let diff = new Date(currentTime) - new Date(userToken);
      if (diff > minimumTimeForCreatingNewToken) {
        this.$http
          .post(api.VISITOR_COUNTER)
          .then((res) => {
            localStorage.setItem("userToken", JSON.stringify(currentTime));
          })
          .catch((error) => {
            console.log(error.response.data);
          });
      }
    },
  },
};
</script>

<style scoped>
.header {
  /* background-color: beige; */
  background-color: white;
  width: 100%;
  /* overflow: hidden; */
}

.banner_color {
  background-image: linear-gradient(
    to right,
    #37ff49,
    #9ce000,
    #cbbf00,
    #e99900,
    #f77200,
    #f77200,
    #f77200,
    #f77200,
    #e99900,
    #cbbf00,
    #9ce000,
    #37ff49
  );
}

.banner_img {
  /* padding: 32px; */
  display: flex;
  align-items: center;
  position: relative;
}

.banner_img_backdrop {
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  /* display: none; */
}

.logo {
  width: 150px;
}

.name {
  font-size: 2rem;
  font-weight: 600;
}
.alternative_name {
  font-size: 1.5rem;
}
.address {
  font-size: 1.3rem;
  font-weight: 500;
  /* white-space: pre; */
}
.info_container {
  color: white;
  padding-left: 20px;
}

.second_logo_container {
  padding: 20px;
}

@media only screen and (max-width: 960px) {
  .name {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .address {
    font-size: 1;
  }

  .second_logo_container {
    /* height: 80px;
    width: 80px;
    display: flex;
    justify-content: flex-end;
    padding: 0; */
    display: none;
  }
}
@media only screen and (max-width: 700px) {
  .banner_img {
    padding: 10px;
  }
  .logo {
    width: 110px;
  }
  .info_container {
    padding-left: 10px;
  }
  .name {
    font-size: 20px;
    font-weight: 600;
  }
  .address {
    font-size: 16px;
  }
}
@media only screen and (max-width: 500px) {
  .banner_img {
    padding: 20px 10px;
  }
  .logo {
    width: 90px;
  }
  .name {
    /* font-size: 16px; */
    font-weight: 600;
  }
  .address {
    font-size: 16px;
  }
}
</style>
