<template>
  <v-sheet>
    <v-row class="">
      <v-col v-if="numberOfSkeletonNeedToBeGenerated == 1">
        <v-skeleton-loader width="98%" :type="skeletonType"></v-skeleton-loader>
      </v-col>
      <v-col
        v-else
        cols="3"
        v-for="i in numberOfSkeletonNeedToBeGenerated"
        :key="i"
      >
        <v-skeleton-loader :type="skeletonType"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import axios from "axios";
export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  props: {
    skeletonType: {
      type: String,
      default: "image",
    },
    numberOfSkeletonNeedToBeGenerated: {
      type: Number,
      default: 1,
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
>
