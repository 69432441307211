<template>
  <div>
    <div class="body_container">
      <div class="box">
        <div class="d-flex">
          Latest Notices
          <v-icon
            @click="marqueePause = !marqueePause"
            size="14px"
            color="black"
            class="ml-3"
            style="box-shadow:none;"
            >{{ marqueePause ? "fa-play" : "fa-pause" }}</v-icon
          >
        </div>
        <div class="box_arrow"></div>
      </div>
      <div class="marquee_body">
        <div style="overflow: hidden">
          <div class="data_container" :class="{animation_paused:marqueePause}">
            <div class="data" v-for="i in 15" :key="i">
              {{i}}
              Lorem ipsum dolor sit amet consectetur
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Demo from "../components/home/marqueeNoticeDepricated.vue";
export default {
  beforeMount() {},
  components: {
    Demo,
  },
  computed: {},
  data() {
    return {
      marqueePause: false,
    };
  },
  methods: {},
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.body_container {
  border: 1px solid red;
  /* overflow-y: scroll; */
  display: flex;
  align-items: stretch;

  overflow: hidden;
  background-color: lightgoldenrodyellow;
  padding: 15px;
}
.box {
  padding: 10px 15px;
  background-color: brown;
  position: relative;

  flex: 0 0 15%;
}

.box_arrow {
  position: absolute;
  left: calc(100% - 5px);
  top: calc(50% - 5px);
  height: 10px;
  width: 10px;
  background-color: brown;
  transform: rotate(45deg);
}
.marquee_body {
  /* flex-basis: 8; */

  overflow: hidden;
  padding-left: 10px;
}
.data_container {
  border: 1px solid lightcoral;
  padding: 10px;
  display: inline-block;
  white-space: nowrap;
  background-color: lightslategrey;
  animation: animate 30s linear infinite;
}
.data_container:hover {
  animation-play-state: paused;
}
.animation_paused{
  animation-play-state: paused;

}
.marquee {
  background-color: red;
}
@keyframes animate {
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.data {
  border-left: 3px solid coral;
  padding: 5px 15px;
  display: inline-block;
}
</style>
