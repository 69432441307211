<template>
  <div class="navBar py-2" id="navBar">
    <input type="checkbox" id="nav_select" />
    <v-container class="navItemContainer py-0 pl-0">
      <ul class="pa-0">
        <div class="navItem">
          <v-menu
            open-on-hover
            :offset-y="true"
            v-for="(menu, menuIndex) in menuData"
            :key="menuIndex"
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="menuClickHandler(menu)"
                class="navItemData "
                :class="{ selectedMenu: menu.key == selectedMenu }"
                v-bind="attrs"
                v-on="on"
              >
                {{ menu.name }}
              </span>
            </template>

            <v-list rounded v-if="menu.active_submenus.length > 0">
              <span
                v-for="(submenu, submenuIndex) in menu.active_submenus"
                :key="submenuIndex"
              >
                <v-list-item
                  @click="menuClickHandler(menu, submenu)"
                  tag="v-list-item"
                >
                  <v-list-item-title>{{ submenu.name }}</v-list-item-title>
                </v-list-item>
              </span>
            </v-list>
          </v-menu>
        </div>
      </ul>
    </v-container>
    <div class="navbar_icon_container">
      <router-link class="nav_home" tag="a" to="/">
        <v-icon>fas fa-home</v-icon>
      </router-link>
      <label for="nav_select">
        <v-icon>fas fa-bars</v-icon>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  beforeDestroy() {
    window.removeEventListener("click", this.userClickHandler);
  },
  created() {
    window.addEventListener("click", this.userClickHandler);
  },
  data() {
    return {
      selectedMenu: null,
    };
  },

  methods: {
    menuClickHandler(menu = null, submenu = null) {
      let link = null;
      if (menu.key == "home") {
        link = "/";
      }
      if (menu.key == "contact") {
        link = "/contact";
      }

      if (submenu != null) {
        if (submenu.submenuType == "co_curriculum") {
          link = "/curriculum/" + submenu.key;
        }

        if (submenu.submenuType == "details") {
          link = "/details/" + submenu.key;
        }
        if (submenu.submenuType == "information") {
          link = "/information/" + submenu.key;
        }

        if (submenu.submenuType == "facility") {
          link = "/facility/" + submenu.key;
        }

        if (submenu.submenuType == "home") {
          link = "/home/" + submenu.key;
        }

        if (submenu.submenuType == "pdf") {
          link = "/notice/" + submenu.key;
        }

        if (submenu.submenuType == "table") {
          link = "/" + submenu.key;
        }

        if (submenu.submenuType == "activity") {
          link = "/events?type=" + submenu.key;
        }

        if (submenu.submenuType == "gallery") {
          link = "/gallery/" + submenu.key;
        }

        if (submenu.key == "events") {
          link = "/" + submenu.key;
        }
      }
      if (link != null) {
        this.$router.push(link);
        this.selectedMenu = menu.key;
      }
    },
    userClickHandler(event) {
      // let cardHtml = document.getElementById("menuCard");
      // if (cardHtml && this.showMenu) {
      //   this.showMenu = cardHtml.contains(event.target) ? true : false;
      // }
      if (screen.width <= 960) {
        let nav_select = document.getElementById("nav_select");
        let navBar = document.getElementById("navBar");

        if (nav_select && nav_select.checked) {
          nav_select.checked = navBar.contains(event.target) ? true : false;
        }
      }
    },
  },
  props: {
    menuData: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
#nav_select {
  display: none;
}
.navBar {
  text-align: center;
  padding: 0px 0;
  background-color: rgba(38, 255, 255, 0.5);
  /* background-color: #8A1D00; */
  /* background-color: #888484; */
  /* border-radius: 10px; */
  /* background-color: green; */
  /* opacity: 2; */
}
.navItem {
  display: inline-block;
  cursor: pointer;
  margin: 0;
}
.navItemData {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 7px !important;
  color: rgb(59, 52, 52);
  letter-spacing: 0.5px;
}
.navItemData:hover {
  color: rgb(43, 122, 226);
  border-bottom: 1px solid blueviolet;
}

.selectedMenu {
  background-color: rgba(86, 86, 255, 0.315);
  border-radius: 5px;
  /* padding-bottom: 10px; */
}

.navbar_icon_container {
  text-align: right;
  padding: 10px 15px;
  /* color: black !important; */
  color: white !important;
  display: none;
}
.navbar_icon_container i {
  color: #004346;
}

@media only screen and (max-width: 1264px) {
  .navBar {
    position: relative;
  }
  .navItemContainer {
    width: 250px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: teal;
    text-align: right;
    position: fixed;
    top: 0px;
    left: -100%;
    transition: 0.3s;
    z-index: 40;
    padding: 40px!important;
    padding-bottom: 40px !important;
    scrollbar-width: none
  }
  .navItemContainer::-webkit-scrollbar { 
    width: 0;
    height: 0;
}
  .navItemData {
    display: block;
    width: 200px;
    background-color: blanchedalmond;
    margin: 10px 0;
    text-align: right;
    /* border-radius: 9px; */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 15px 15px !important;
  }

  .navbar_icon_container {
    display: block;
    position: relative;
  }
  .nav_home {
    position: absolute;
    left: 15px;
    text-decoration: none;
    color: white !important;
  }
  #nav_select:checked ~ .navItemContainer {
    left: 0%;
  }
}
</style>
>
