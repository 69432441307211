<template>
  <v-row class="">
    <v-col cols="2" class="pa-0">
      <div class="box">
        Latest Notices
        <v-icon
          @click="marqueePause = !marqueePause"
          size="14px"
          color="white"
          class="ml-3"
          >{{ marqueePause ? "fa-play" : "fa-pause" }}</v-icon
        >
        <div class="box_arrow"></div>
      </div>
    </v-col>
    <v-col cols="10" class="pa-0">
      <div class="marquee_container">
        <div style="overflow: hidden">
          <div
            style=""
            class="marquee"
            :class="{ animation_pause: marqueePause }"
          >
            <div style="border: 3px solid gray" class="d-inline-block">
              <div class="marquee_content">
                <span class="marquee_item" v-for="i in 10" :key="i">
                  <router-link tag="a" :to="'https:///www.google.com'">
                    Facebook Facebook Facebook Facebook Facebook Facebook
                  </router-link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </v-col>
  </v-row>
</template>

<script>
export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {
      marqueePause: true,
    };
  },
  methods: {},
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.box {
  padding: 10px 15px;
  background-color: brown;
  position: relative;
  height: 100%;
}

.box_arrow {
  position: absolute;
  left: calc(100% - 5px);
  top: calc(50% - 5px);
  height: 10px;
  width: 10px;
  background-color: brown;
  transform: rotate(45deg);
}
.marquee_container {
  background-color: burlywood;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-left: 20px;
}

.marquee {
  height: 100%;
  display: inline-block;
  animation: marquee 10s linear infinite;
  position: relative;
}
.marquee:hover {
  animation-play-state: paused;
}

.animation_pause {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee_content {
  height: 100%;
  display: flex;
  align-items: center;
  /* position: absolute;
  height: 100px;
  left: 0;
  top: 0;
  width: 100px;*/
  border: 3px solid blue;
}

.marquee_item {
  padding: 7px 15px;
  border: 1px solid green;
  margin: 0 10px;
  display: inline-block;
  flex-shrink: 0;
}
.marquee_item a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.marquee_item a:hover {
  color: blueviolet;
}
</style>
