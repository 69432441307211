<template>
  <v-row>
    <v-col cols="12">
      <loader v-if="loadingState" />

      <div v-else>
        <v-row v-if="data.length < 1">
          <no-data class="mx-auto" />
        </v-row>
        <v-row v-else>
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="4"
            sm="6"
            v-for="(image, index) in data"
            :key="index"
          >
            <image-modal :imgData="image" />
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import * as api from "../config/api.js";
import ImageModal from "../components/modal/imageModal.vue";
export default {
  beforeMount() {
    this.getData();
  },
  components: {
    ImageModal,
  },
  computed: {},
  data() {
    return {
      loadingState: false,
      data: [],
    };
  },
  methods: {
    getData() {
      this.loadingState = true;
      this.$http
        .get(api.GALLERY_IMAGES)
        .then((res) => {
          this.data = res.data;
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
