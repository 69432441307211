import Vue from "vue";
import App from "./App.vue";
import vuetify from "./config/vuetify";

import router from "./config/router.js";
import axios from './config/axios.js'

import ImgSkeleton from './components/loader/skeletonLoader.vue'
import NoData from './components/noData.vue'

Vue.component('loader', ImgSkeleton);
Vue.component("no-data", NoData);
Vue.config.productionTip = false;
Vue.prototype.$http = axios;

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
