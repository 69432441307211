<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else>
      <div v-if="sectionDoesNotContainData" class="text-center">
        <no-data />
      </div>
      <div v-else>
        <description-with-images
          :images="images"
          :description="description"
          :headline="headline"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
import ImageModal from "../components/modal/imageModal.vue";
import DescriptionWithImages from "../components/descriptionWithImages.vue";
export default {
  beforeMount() {
    this.getData();
  },
  components: {
    ImageModal,
    DescriptionWithImages,
  },
  computed: {},
  data() {
    return {
      loadingState: false,
      id: this.$route.params.id,
      headline: null,
      images: [],
      description: null,
      sectionDoesNotContainData: false,
    };
  },
  methods: {
    reset() {
      this.images = [];
      this.description = null;
      this.loadingState = false;
      this.sectionDoesNotContainData = false;
    },
    getData() {
      this.reset();
      this.loadingState = true;
      this.$http
        .get(api.EVENT_DETAILS + "/" + this.id)
        .then((res) => {
          if (res.data != null) {
            this.images = res.data.images;
            this.description = res.data.description;
            this.headline = res.data.headline;
          } else {
            this.sectionDoesNotContainData = true;
          }
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {
    "$route.params"() {
      this.id = this.$route.params.id;
      this.getData();
    },
  },
};
</script>

<style scoped></style>
