<template>
  <v-col >
    <div >
      <div class="important_links_board_headline section_headline">
        Important Links
      </div>
      <div v-if="isComponentLoading">
        <skeleton-loader :skeletonType="'image'" />
      </div>
      <div class="important_links_board" v-else>
        <div>
          <div v-for="(importantLink, index) in importantLinks" :key="index">
            <v-row class="important_link">
              <v-col cols="2" class="align-items-center d-flex">
                <v-icon class="important_link_icon">
                  fa-external-link-alt
                </v-icon>
              </v-col>
              <v-col
                cols="8"
                class="linkHeadline px-0"
                @click="gotoLink(importantLink.link)"
              >
                {{ importantLink.headline }}
              </v-col>
            </v-row>
            <div class="hrLine"></div>
          </div>
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
import SkeletonLoader from "../loader/skeletonLoader.vue";
export default {
  beforeMount() {},
  components: {
    SkeletonLoader,
  },
  computed: {},
  data() {
    return {};
  },
  methods: {
    gotoLink(link) {
      window.open(link);
    },
  },
  mounted() {},
  props: {
    importantLinks: {
      type: Array,
      default: () => [],
      required: true,
    },
    isComponentLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>
.important_links_board {
  border: 1px solid rgba(160, 161, 161, 0.404);
  /* border-radius: 5px; */
  /* box-shadow: 2px 2px 8px 8px rgb(199, 201, 202); */
  /* padding: 5px; */
  height: 100%;
  padding-top: 15px;
  position: relative;
  /* background-color: rgba(248, 248, 215, 0.671); */
  /* background-color: #C4C4C4; */
  /* background-color: rgb(235, 229, 229); */
  background-color: #F5F5F5;
}
.important_links_board_headline {
  text-align: center;
  font-weight: 500;
  color: black;
  padding: 5px 0;
  /* margin-bottom: 15px; */
  border-bottom: 1px solid rgb(199, 201, 202);
  background-color: #969191;
  /* border-radius: 10px; */
}
.hrLine {
  height: 1px;
  width: 98%;
  margin: 10px auto;
  border-bottom: 1px solid rgba(0, 9, 27, 0.479);
}
.linkHeadline {
  font-size: 18px;
  font-weight: 500;
  /* color: rgb(26, 23, 23); */
  color: black;
  cursor: pointer;
}
.important_link {
  padding: 0 0 0 10px;
}
.important_link:hover .linkHeadline {
  color: rgb(32, 118, 168);
}
.important_link_icon {
  color: rgba(27, 139, 230, 0.699) !important;
  font-size: 16px !important;
  margin-right: 5px;
}
</style>
>
