<template>
  <v-row>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="6"
      v-for="(video, index) in videos"
      :key="index"
    >
      <div style="position: relative">
        <iframe :src="video.link" width="100%" height="auto" frameborder="0">
        </iframe>
        <div class="backdrop" @click="modalOpenHandler(video.link)"></div>
      </div>
    </v-col>

    <v-dialog v-if="dialog" v-model="dialog" :width="getWidth">
      <div style="position: relative; height: 500px">
        <iframe :src="link" width="100%" height="100%" frameborder="0">
        </iframe>
        <v-btn class="close_button" fab dark small @click="modalCloseHandler">
          X
        </v-btn>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  beforeMount() {},
  components: {},
  computed: {
    getWidth() {
      if (screen.width < 960) {
        return "100%";
      }
      return "50%";
    },
  },
  data() {
    return {
      link: null,
      dialog: false,
    };
  },
  methods: {
    modalOpenHandler(link) {
      this.link = link;
      this.dialog = true;
    },
    modalCloseHandler() {
      this.link = null;
      this.dialog = false;
    },
  },
  mounted() {},
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>
.backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background-color: red; */
  opacity: 0;
}
.backdrop:hover {
  background-color: rgb(0, 0, 0);
  opacity: 0.2;
}
.close_button {
  position: absolute;
  top: 0px;
  right: 1px;
  font-weight: 600;
  font-size: 16px;
  background-color: rgba(255, 0, 0, 0.445) !important;
}
</style>
