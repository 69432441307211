<template>
  <v-app class="">
    <!-- <v-img src="./assets/bg_main.gif"> -->
    <div class="contents">
      <v-container class="pageContainer py-0 " style="padding-bottom: 11px !important;">
        <Header class=""/>
        
        <router-view class="" style="min-height: calc(100vh - 300px)"></router-view>
        <br>
        <Footer class="" />
        <!-- <br> -->
      </v-container>
    </div>

    <!-- </v-img> -->
  </v-app>
</template>

<script>
import Header from "./components/header/header.vue";
import Footer from "./components/footer/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
/* Google Font */
@import url("https://fonts.googleapis.com/css2?family=Texturina");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu");
@import url("https://fonts.googleapis.com/css2?family=Lato");
/* Basic CSS */
@import url("./css/basicBootstrap.css");
@import url("./css/globalStyles.css");
</style>

<style scoped>
.pageContainer {
  /* background-color: #f7f5e5 !important; */
  /* background-color: #e8e8e8 !important; */
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 48px;
  /* width: 75% !important; */
}
.contents {
  /* background-image: url("../public/bg.png"); */
  /* filter: grayscale(100%); */
  /* background-repeat: repeat; */
  background-color: #dae9ff !important;
  box-sizing: border-box;
  overflow: hidden;
}

@media only screen and (max-width: 1260px) {
  .pageContainer{
    padding: 0 20px;
  }
}
@media only screen and (max-width: 959px) {
  .pageContainer{
    padding: 0px;
    width: 100%;
  }
}
</style>
