<template>
  <v-row class="single_notice">
    <v-col
      cols="3"
      xl="3"
      lg="3"
      md="3"
      sm="3"
      class="notice_board_notice_date "
    >
      <span class="text-center mx-auto">
        <v-icon class="notice_board_notice_icon"> fa-calendar-alt </v-icon>
        {{ getDate(noticeData.created_at) }}
      </span>
    </v-col>
    <v-col cols="9" class="single_notice_data" @click="noticeView(noticeData)">
      {{ convertNoticeHeadline(noticeData.headline) }}
      <span style="color: #3f51b5; font-size: smaller">...(see more)</span>
    </v-col>
  </v-row>
</template>

<script>
import * as slug from "../../config/slug.js";
export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {
    getDate(date) {
      let monthArray = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let convertedDate = new Date(date);
      convertedDate = ` 
        ${monthArray[convertedDate.getMonth()]} 
        ${convertedDate.getDate()}, ${convertedDate.getFullYear()}
      `;
      return convertedDate;
    },
    convertNoticeHeadline(notice) {
      let data = notice; 
      if(screen.width>980){
         data = notice.length > 50 ? notice.slice(0, 90) : notice;
      }else{
         data = notice.length > 50 ? notice.slice(0, 50) : notice;

      }
      return data;
    },
    noticeView(notice) {
      this.$router.push(slug.VIEW_NOTICE + "/" + notice.id);
    },
  },
  mounted() {},
  props: {
    noticeData: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>
.single_notice {
  padding: 0 0 0 10px;
}
.single_notice:hover {
  /* background-color: rgba(48, 48, 49, 0.26); */
  border-radius: 10px;
}
.single_notice_data {
  font-size: 16px;
  /* font-weight: 600; */
  letter-spacing: 0.5px;
  /* color: rgb(0, 0, 0); */
  color: #666;
  cursor: pointer;
}
.single_notice:hover .single_notice_data {
  color: #0a4bff;
}
.notice_board_notice_date {
  display: flex;
  align-items: center;
  color: #666;
}
.notice_board_notice_icon {
  /* color: rgba(27, 139, 230, 0.699) !important; */
  color: rgba(6, 7, 7, 0.699) !important;
  font-size: 16px !important;
  margin-right: 5px;
}

@media only screen and (max-width: 600px) {
  .notice_board_notice_date {
    /* display: block; */
    text-align: center;
    font-size: 14px;
    /* font-weight: 600; */
  }
  .notice_board_notice_icon {
    display: block;
  }
}
</style>
