<template>
  <v-row>
    <v-col cols="12" class="">
      <loader v-if="loadingState" />
      <div v-else>
        <div v-if="!picPath && !description" class="text-center">
          <no-data />
        </div>
        <h1
          class="text-center mb-4"
          v-if="submenuName"
          style="background-color: #bbdefb"
        >
          {{ this.submenuName }}
        </h1>
        <div class="">
          <v-img
            :src="picPath"
            width="100%"
            style="border-radius: 5px"
            v-if="picPath"
            class="mx-auto"
            max-height="350px"
            contain
          >
          </v-img>
        </div>
        <br />
        <div
          v-html="description"
          class="briefDescription"
          v-if="description"
        ></div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import SkeletonLoader from "../components/loader/skeletonLoader.vue";
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getData();
  },
  components: { SkeletonLoader },
  computed: {},
  data() {
    return {
      loadingState: false,
      picPath: null,
      description: null,
      id: this.$route.params.id,
      submenuName: null,
    };
  },
  methods: {
    reset() {
      this.picPath = null;
      this.description = null;
      this.loadingState = false;
    },
    getData() {
      this.reset();
      this.loadingState = true;
      this.$http
        .get(api.DETAILS + "/" + this.id)
        .then((res) => {
          if (res && res.data.picPath && res.data.description) {
            this.picPath = res.data.picPath;
            this.description = res.data.description;
          }
          if (res && res.data.submenuName) {
            this.submenuName = res.data.submenuName;
          }
          console.log(res.data);
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {
    "$route.params"() {
      this.id = this.$route.params.id;
      this.getData();
    },
  },
};
</script>

<style scoped>
.briefDescription {
  font-size: 16px;
  word-spacing: 2px;
  /* letter-spacing: .5px; */
  word-break: keep-all;
  line-height: 1.5;
  color: black;
  text-align: justify;
  padding: 25px;
  background-color: white;
  text-rendering: optimizeLegibility;
}
</style>
