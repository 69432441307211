<template>
  <div>
    <!-- <v-btn @click="fontSizeHandler('inc')">
      <v-icon>fas fa-plus</v-icon>
    </v-btn> -->
    <div
      v-html="description"
      class="description"
      id="descriptionText"
      v-if="description"
    ></div>
  </div>
</template>

<script>
export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {
    fontSizeHandler(operation) {
      let data = document.getElementById("descriptionText");
      data.style.removeProperty("font-size");
    },
  },
  mounted() {},
  props: {
    description: {
      type: String,
      default: null,
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>
.description {
  font-size: 16px !important;
  /* font-size: 16px !important; */
  word-spacing: 2px;
  /* letter-spacing: .5px; */
  word-break: keep-all !important;
  line-height: 1.5;
  color: black;
  text-align: justify;
  padding: 25px;
  background-color: white;
  text-rendering: optimizeLegibility;
}

.description li {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .description {
    padding: 15px;
  }

  h1 {
    display: none !important;
  }
}
@media only screen and (max-width: 400px) {
  .description {
    padding: 5px;
    text-align: left !important;
  }
}
</style>
