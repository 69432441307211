<template>
  <v-row>
    <v-col cols="12">
      <loader v-if="loadingState" />
      <div v-else>
        <v-row v-if="dataArray.length > 0" class="justify-content-center">
          <v-col
            v-for="(event, index) in dataArray"
            :key="index"
            cols="12"
            xl="3"
            lg="3"
            md="4"
            sm="12"
          >
            <div @click="eventRouteHandler(event)" class="event_container">
              <div class="event_img_container">
                <v-img :src="event.bannerPicPath"></v-img>
              </div>
              <div class="event_data_container">
                <div class="event_headline">
                  {{ event.headline }}
                </div>
                <div class="event_date">
                  <v-icon class="event_date_icon">fa-calendar-alt</v-icon>
                  {{ getDate(event.created_at) }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div v-else class="text-center">
          <no-data />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { EVENTS } from "../config/slug.js";
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getData();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      dataArray: [],
    };
  },
  methods: {
    getData() {
      this.dataArray = [];
      let type = this.$route.query.type ? `/${this.$route.query.type}` : "";
      this.loadingState = true;
      this.$http
        .get(api.EVENTS + type)
        .then((res) => {
          if (res && res.data != null) {
            this.dataArray = res.data;
          }
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getDate(date) {
      let monthArray = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let convertedDate = new Date(date);
      convertedDate = ` 
      ${monthArray[convertedDate.getMonth()]} 
      ${convertedDate.getDate()}, 
      ${convertedDate.getFullYear()}`;
      return convertedDate;
    },
    eventRouteHandler(event) {
      this.$router.push(EVENTS + "/" + event.id);
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {
    "$route.query"() {
      this.getData();
    },
  },
};
</script>

<style scoped>
.event_container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  height: 100%;
}

.event_headline {
  font-size: 20px;
  margin-top: 15px;
}

.event_date {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-weight: 600;
  letter-spacing: 1px;
}

.event_date_icon {
  font-size: 18px;
  color: rgb(158, 140, 240);
  margin-right: 10px;
}

@media only screen and (max-width: 960px) {
  .event_container {
    display: flex;
    align-items: center;
  }
  .event_img_container {
    width: 30%;
    display: inline-block;
  }

  .event_data_container {
    display: inline-block;
    /* align-items: center; */
    margin-left: 15px;

    /* border: 1px solid red ; */
  }
  .event_headline {
    margin-top: 0;
  }
}

@media only screen and (max-width: 450px) {
  .event_headline {
    font-size: 18px;
  }
  .event_date {
    font-size: 12px;
  }
}
</style>
