export const HOST = "http://backend.hatikhalahighschool.edu.bd";
// export const HOST = "http://backend.bpac.edu.bd";
// export const HOST = "https://backend.imsn.edu.bd";
// export const HOST = "https://backend.razapurcollege.edu.bd";
// export const HOST = "https://backend.talebhma.edu.bd";
// export const HOST = "https://backend.khmc.edu.bd";
// export const HOST = "https://backend.basacfulkuri.edu.bd";
// export const HOST = "https://backend.nababganjdakhilmadrasah.edu.bd";
// export const HOST = "https://backend.schooltwo.dcampusweb.com";
// export const HOST = "http://localhost:8000";
