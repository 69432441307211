<template>
  <div class="no_data">
    <v-icon class="no_data_icon">far fa-frown</v-icon> No Data Available
  </div>
</template>

<script>
export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.no_data_icon {
  font-size: inherit;
  color: rgb(37, 36, 54);
}
.no_data {
  font-size: 36px;
  color: black;
  display: inline-block;
  padding: 20px 55px;
  border: 1px solid rgba(56, 78, 126, 0.541);
  border-radius: 20px;
  margin-top: 150px;
  background-color: rgb(255, 255, 255);
}

@media only screen and (max-width: 500px) {
  .no_data {
    font-size: 20px;
    margin-top: 50px;
  }
}
</style>
