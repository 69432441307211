<template>
  <div class="">
    <div class="notice_board_headline section_headline">Notice Board</div>
    <div v-if="isComponentLoading">
      <skeleton-loader :skeletonType="'image'" />
    </div>
    <div v-else class="notice_board">
      <div>
        <div v-for="(notice, index) in notices" :key="index">
          <notice :noticeData="notice" />
          <div class="hrLine"></div>
        </div>
        <!-- <div class="text-right"> -->
        <br />
        <br />
        <v-btn :to="getAllNotice" class="load_more_notice_btn" outlined>
          More <v-icon class="ml-2">fa-angle-double-right</v-icon>
        </v-btn>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import * as slug from "../../config/slug.js";
import Notice from "./notice.vue";
import SkeletonLoader from "../loader/skeletonLoader.vue";
export default {
  beforeMount() {},
  components: {
    SkeletonLoader,
    Notice,
  },
  computed: {
    getAllNotice() {
      return slug.ALL_NOTICE;
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  props: {
    notices: {
      type: Array,
      default: () => [],
      required: true,
    },
    isComponentLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>
.notice_board {
  /* border: 1px solid rgba(160, 161, 161, 0.404); */
  border: 1px solid #9b9b9b7a;
  border-radius: 1px;
  /* box-shadow: 2px 2px 8px 8px rgb(199, 201, 202); */
  padding: 0px;
  padding-top: 15px;
  height: 100%;
  position: relative;
  /* background-color: rgba(248, 248, 215, 0.671); */
  /* background-color: #c4c4c4; */
  /* background-color: rgb(235, 229, 229); */
  background-color: #F5F5F5;
}
.notice_board_headline {
  text-align: center;
  /* font-size: 25px; */
  font-weight: 500;
  color: black;
  padding: 5px 0;
  /* margin-bottom: 15px; */
  /* border-bottom: 1px solid rgb(199, 201, 202); */
  /* background-color: rgb(69, 119, 212); */
  background-color: #969191;
  /* border-radius: 10px; */
}
.hrLine {
  height: 1px;
  width: 98%;
  margin: 10px auto;
  border-bottom: 1px solid rgba(150, 161, 185, 0.479);
}
.load_more_notice_btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgb(25, 26, 26);
  color: white !important;
}
</style>
>
