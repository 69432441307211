<template>
  <v-row class="">
    <v-col cols="12">
      <loader type="image" v-if="loadingState" />
      <div v-else>
        <h1 class="speech_headline mb-2">{{ speechData.headline }}</h1>
        <div style="text-align: center">
          <v-img
            :src="speechData.picPath"
            max-width="300px"
            width="100%"
            contain
            class="d-inline-block"
          ></v-img>
          <div class="">
            <div class="speechName ma-0">{{ speechData.name }}</div>
          </div>
        </div>
        <br />
        <v-sheet class="speech" v-html="speechData.speech"></v-sheet>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.speechId = this.$route.query.mid;
    this.getspeechData();
  },
  components: {},
  computed: {},
  data() {
    return {
      speechId: null,
      speechData: {},
      error: false,
      loadingState: false,
    };
  },
  methods: {
    getspeechData() {
      this.loadingState = true;
      this.$http
        .get(api.SPEECH + "/" + this.speechId)
        .then((res) => {
          if (res.data && res.data.name && res.data.speech) {
            this.speechData = res.data;
          }
        })
        .catch((error) => {
          //   alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.speech_headline {
  background-color: rgb(73, 73, 73);
  color: white;
  text-align: center;
}
.speechName {
  font-size: 20px !important;
  padding: 5px 0;
  /* font-weight: 600; */
  text-align: center;
  margin: 7px 0;
  color: rgb(255, 255, 255);
  letter-spacing: 1.4px;
  line-height: 1.2rem;
  font-size: 0.9rem;
  background-color: rgb(73, 73, 73);

  display: inline-block;
  padding: 5px 40px;
  border-radius: 5px;
}

.speech {
  padding: 16px;
  line-height: 1.4rem;
  font-size: 1.2rem;
  text-align: justify;
}
</style>
