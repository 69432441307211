<template>
  <v-row style="" class="notice-card">
    <v-col cols="3" class="center">
      <div class="text-center">
        <v-icon class="mr-2 date_icon">fa-calendar-alt</v-icon>
        <div class="notice_date">{{ getDate(noticeData.created_at) }}</div>
      </div>
    </v-col>
    <v-col
      cols="9"
      xl="7"
      lg="7"
      md="7"
      sm="7"
      class="center notice_headline"
    >
      <div class="">
        <div>{{ noticeData.headline }}</div>
        <div v-if="noticeData.totalViews" class="total_view">
          Total Views :
          <span style="margin-left: 10px; font-weight: 600">{{
            noticeData.totalViews
          }}</span>
        </div>
      </div>
    </v-col>
    <v-col
      cols="12"
      xl="2"
      lg="2"
      md="2"
      sm="2"
      class="center notice_button_container  justify-content-center"
    >
      <div class="">
        <div class="noticeButton" @click="viewButtonHandler">
          <!-- <v-btn color="#fff" @click="viewButtonHandler"> -->
          <v-icon class="download_icon">fa-eye</v-icon>
          <!-- </v-btn> -->
        </div>

        <div class="noticeButton" @click="downloadButtonHandler">
          <!-- <v-btn color="#fff" > -->
          <v-icon class="download_icon">fa-download</v-icon>
          <!-- </v-btn> -->
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import * as slug from "../../config/slug.js";
export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {
    getDate(date) {
      let monthArray = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let convertedDate = new Date(date);
      convertedDate = ` 
      ${monthArray[convertedDate.getMonth()]} 
      ${convertedDate.getDate()}, 
      ${convertedDate.getFullYear()}`;
      return convertedDate;
    },
    viewButtonHandler() {
      this.$router.push(slug.VIEW_NOTICE + "/" + this.noticeData.id);
    },
  },
  mounted() {},
  props: {
    noticeData: {
      type: Object,
      required: true,
      default: () => {},
    },
    downloadButtonHandler: {
      type: Function,
      default: () => {},
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>
.center {
  display: flex;
  align-items: center;
}
.notice-card {
  border: 1px solid rgba(88, 88, 88, 0.534);
  border-radius: 5px;
  padding: 0 5px;
  margin-bottom: 15px;
  cursor: pointer;
  /* background-color: white; */
  background-color: #C4C4C4;
  background-color: #F5F5F5;
  /* background-color: #474554; */
  
}
.notice-card  * {
  color: black !important;
}
.notice-card:hover {
  /* background-color: rgba(194, 218, 255, 0.747); */
  background-color: #5e7e7dd7;
}
.notice_headline {
  font-size: 18px;
  /* justify-content: center; */
}
.download_icon {
  /* color: rgb(80, 106, 255) !important; */
  /* color: rgb(80, 106, 255) !important; */
  font-size: 16px;
}
.noticeButton {
  display: inline-block;
  margin: 5px 3px;
  /* border: 1px solid rgb(123, 203, 228); */

  border-radius: 5px;
  padding: 3px 20px;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
}
.noticeButton:hover {
  background-color: rgba(216, 216, 216, 0.459);
}
.total_view {
  font-size: 16px;
  font-weight: 500;
  color: rgb(42, 42, 87);
}

@media only screen and (max-width: 700px) {
  .vr_line {
    height: 100%;
    width: 1px;
    border: 1px solid rgb(70, 69, 69);
    border-radius: 5px;
    margin: 0 5px;
  }
  .notice_date {
    font-size: 0.8rem;
    /* display: block; */
    /* width: 100%; */
  }

  .notice_button_container {
    padding: 0;
  }

  /* .noticeButton {
    width: 100%;
    text-align: center;
    padding: 3px 10px;
  } */
}

@media only screen and (max-width: 500px) {
  .notice_headline {
    justify-content: center;
  }
}
</style>
