<template>
  <div style="height: 100%">
    <div class="imageContainer">
      <div>
        <v-img
          :src="imgData.picPath"
          width="100%"
          height="100%"
          aspect-ratio="1.7"
          style="position: relative"
          lazy-src="../../assets/lazy_load.png"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
          <div class="backdrop">
            <div class="backdrop_icon_container">
              <v-icon class="backdrop_icon zoom_in" @click="dialog = true">
                <!-- fa-plus-circle -->
                fa-eye
              </v-icon>
            </div>
          </div>
        </v-img>
        <div class="description" v-if="imgData.description">
          {{ imgData.description }}
        </div>
      </div>
    </div>
    <div>
      <v-dialog v-model="dialog" width="50%" class="border-danger">
        <div class="modal">
          <v-img :src="imgData.picPath" contain width="100%">
            <v-btn class="close_button" fab dark small @click="dialog = false">
              X
            </v-btn>
          </v-img>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {} from "../../config/api.js";

export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {
      dialog: false,
    };
  },
  methods: {},
  mounted() {},
  props: {
    imgData: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>
.imageContainer {
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  padding: 20px;
  height: 100%;
  align-items: center;
  background-color: white;
  /* border: 1px solid red; */
  /* -webkit-box-shadow: -1px 3px 8px 6px rgba(155, 158, 160, 0.62); */
  /* box-shadow: -1px 3px 8px 6px rgba(198, 213, 226, 0.62); */
}

.imageContainer .description {
  font-size: 16px;
  text-align: center;
  color: black;
  background-color: white;
  padding: 5px;
  padding-bottom: 0;
}

.close_button {
  position: absolute;
  top: 0px;
  right: 1px;
  font-weight: 600;
  font-size: 16px;
  background-color: rgba(255, 0, 0, 0.445) !important;
}

.backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  background-color: rgba(255, 255, 255, 0.315);
  transition: 0.3s;
}

.imageContainer:hover .backdrop {
  left: 0;
}

.backdrop_icon_container {
  padding: 10px 20px;
  text-align: right;
}

.backdrop_icon {
  font-size: 20px !important;
  color: rgb(70, 70, 70);
  border-radius: 5%;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.493);
}
.backdrop_icon:hover{
    color: rgb(31, 31, 31);
  background-color: rgb(255, 255, 255);

}
</style>
