import Vue from "vue";
import VueRouter from "vue-router";

import * as slug from "./slug.js";
Vue.use(VueRouter);

import Home from "../pages/home.vue";
import Details from "../pages/details.vue";
import Information from "../pages/information.vue";
import Notice from "../pages/notice.vue";
import Facility from "../pages/facility.vue";
import Curriculam from "../pages/coCurriculam.vue";
import ViewNotice from "../pages/noticeView.vue";
import Speech from "../pages/speechDetails.vue";

import Teacher from "../pages/teacher.vue";
import Staff from "../pages/staff.vue";
import Committee from "../pages/committee.vue";
import FormerMaster from "../pages/formerHeadmaster.vue";

import Events from "../pages/events.vue";
import EventDetails from "../pages/eventDetails.vue";
import GalleryImages from '../pages/galleryImages.vue'
import GalleryVideos from '../pages/galleryVideos.vue'

import ContactUs from '../pages/contactUs.vue'

import Test from "../pages/test.vue";

const route = (path, component) => {
  return { path, component };
};
const router = new VueRouter({
  mode: "history",

  routes: [
    route(slug.HOME, Home),
    route(slug.DETAILS, Details),
    route(slug.INFORMATION, Information),
    route(slug.NOTICE, Notice),
    route(`${slug.VIEW_NOTICE}/:id`, ViewNotice),
    route(slug.FACILITY, Facility),
    route(slug.CURRICULUM, Curriculam),
    route(slug.TEACHER, Teacher),
    route(slug.STAFF, Staff),
    route(slug.COMMITTEE, Committee),
    route(slug.FORMER_HEADMATER, FormerMaster),
    route(slug.SPEECH, Speech),
    route(slug.EVENTS, Events),
    route(slug.EVENT_DETAILS, EventDetails),
    route(slug.GALLERY_IMAGES, GalleryImages),
    route(slug.GALLERY_VIDEOS, GalleryVideos),
    route(slug.CONTACT_US, ContactUs),

    route("/test", Test),

    // {
    //   path: "*",
    //   redirect: "/",
    // },
  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      //if user click the back button, scroll back into the position where he left
      return savedPosition;
    }
    return {
      //sroll to the top
      x: 0,
      y: 0,
    };
  },
});

export default router;
