<template>
  <v-row>
    <v-col cols="12">
      <loader v-if="loadingState" class="mt-4" />
      <div v-else>
        <div class="text-center" v-if="!description && images.length < 1">
          <no-data />
        </div>

        <h1 class="text-center mb-4" style="background-color: #bbdefb">
          {{ this.submenuName }}
        </h1>

        <div v-if="description">
          <description :description="description" />
        </div>
        <br />
        <div>
          <v-row>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="4"
              sm="6"
              v-for="(image, index) in images"
              :key="index"
            >
              <image-modal :imgData="image" />
            </v-col>
          </v-row>
        </div>
      </div>
      <br>
    </v-col>
  </v-row>
</template>

<script>
import * as api from "../config/api.js";
import ImageModal from "../components/modal/imageModal.vue";
import Description from "../components/description.vue";
export default {
  beforeMount() {
    this.getData();
  },
  components: {
    ImageModal,
    Description,
  },
  computed: {},
  data() {
    return {
      loadingState: false,
      id: this.$route.params.id,
      images: [],
      description: null,
      submenuName: null,
    };
  },
  methods: {
    reset() {
      this.images = [];
      this.description = null;
      this.loadingState = false;
      this.submenuName = null;
    },
    getData() {
      this.reset();
      this.loadingState = true;
      this.$http
        .get(api.FACILITY + "/" + this.id)
        .then((res) => {
          if (res && res.data.images && res.data.description) {
            this.images = res.data.images;
            this.description = res.data.description;
          }
          if (res && res.data.submenuName) {
            this.submenuName = res.data.submenuName;
          }
          console.log(" Error=======>", res.data);
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {
    "$route.params"() {
      this.id = this.$route.params.id;
      this.getData();
    },
  },
};
</script>

<style scoped>
.description {
  font-size: 20px;
  word-spacing: 2px;
  word-break: break-all;
  text-align: justify;
}
</style>
