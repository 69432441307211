<template>
  <v-row class="marquee_container mx-auto">
    <v-col cols="3" xl="2" lg="2" md="2" sm="3" class="pa-0">
      <div class="box">
        Latest Notices
        <div class="box_arrow"></div>
      </div>
    </v-col>
    <v-col
      cols="9"
      xl="10"
      lg="10"
      md="10"
      sm="9"
      class="pa-0 pl-2 d-flex align-items-center"
    >
      <marquee onmouseover="this.stop()" onmouseout="this.start()">
        <!-- <ul class="pa-0 ma-0 d-inline-block"> -->
        <div
          class="d-inline-block"
          v-for="(notice, index) in noticeArray"
          :key="index"
        >
          <div class="d-flex align-items-center">
            <div class="marquee_item pa-0" @click="noticeView(notice.id)">
              {{ notice.headline }}
            </div>
            <div v-if="index!=noticeArray.length-1" class="marquee_item_hr"></div>
          </div>
        </div>
        <!-- </ul> -->
      </marquee>
    </v-col>
  </v-row>
</template>

<script>
import { VIEW_NOTICE } from "../../config/slug.js";
export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {
      marqueePause: true,
    };
  },
  methods: {
    noticeView(id) {
      this.$router.push(VIEW_NOTICE + "/" + id);
    },
  },
  mounted() {},
  props: {
    noticeArray: {
      type: Array,
      default: () => [],
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>
.box {
  padding: 10px 15px;
  background-color: #312f2f;
  position: relative;
  height: 100%;
  color: rgb(218, 218, 218);
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  letter-spacing: 1px;
  line-height: 18px;
}

.box_arrow {
  position: absolute;
  left: calc(100% - 5px);
  top: calc(50% - 5px);
  height: 10px;
  width: 10px;
  background-color: inherit;
  transform: rotate(45deg);
}
.marquee_container {
  /* background-color: burlywood; */
  /* width: 100%; */
  /* height: 100%; */
  overflow: hidden;
  background-color: #7a7a7a;
  /* width: 100%; */
}

.marquee_item {
  padding: 0px 15px !important;
  margin: 0 10px;
  display: inline-block;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: white !important;
  cursor: pointer;
}
.marquee_item_hr {
  display: inline-block;
  background-color: rgb(0, 0, 0);
  border-radius: 3px;
  width: 3px;
  height: 20px;
}


/* .marquee_item a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: white;
} */

.marquee_item:hover {
  color: rgb(20, 20, 20) !important;
}

@media only screen and (max-width: 500px) {
  .box {
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
