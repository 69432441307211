<template>
  <div class="visitor_container">
    <div class="visitor_msg">Today's Visitor  <span class="visitior_counter">{{counterData.todaysVisitor}}</span></div>
    <div class="hr_line"></div>
    <div class="visitor_msg">This month's Visitor  <span class="visitior_counter">{{counterData.thisMonthVisitor}}</span></div>
    <div class="hr_line"></div>
    <div class="visitor_msg">All time Visitor  <span class="visitior_counter">{{counterData.totalVisitor}}</span></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
import * as api from "../../config/api.js";
export default {
  beforeMount() {
    this.getVisitorCounter();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      counterData:{}
    };
  },
  methods: {
    getVisitorCounter() {
      this.loadingState = true;
      this.$http
        .get(api.VISITOR_COUNTER)
        .then((res) => {
          this.counterData = res.data
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>

.visitor_container{
  background-color: rgb(104, 99, 75);
  padding: 20px;
  width: 100%;
}

.visitor_msg{
  font-size: 18px;
  font-weight: 500;
  color: white;
  letter-spacing: 1px;
  text-align: center;

}

.visitior_counter{
  opacity: .9;
  font-size: 25px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  display: block;
}

.hr_line{
  height: 1px;
  border: 2px solid rgba(96, 178, 233, 0.432);
  border-radius: 3px;
  width: 100%;
  margin: 3px 0;
}
</style>
