<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else>
      <div v-if="data.length < 1" class="text-center">
        <no-data class="mx-auto" />
      </div>
      <div v-else>
        <h1
          class="text-center my-2 py-2 mt-6"
          style="background-color: #bbdefb"
        >
          {{ this.submenuName }}
        </h1>
        <data-table
          :tableData="data"
          :tableHeaders="headers"
          :hide-default-footer="true"
          :disable-pagination="false"
          :tableMode="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
import DataTable from "../components/table/table.vue";
export default {
  beforeMount() {
    this.getData();
  },
  components: {
    DataTable,
  },
  computed: {},
  data() {
    return {
      loadingState: false,
      data: [],
      headers: [
        { text: "Image", value: "picPath", sortable: false },
        { text: "Name", value: "name", align: "center" },
        {
          text: "Date of Join",
          value: "dateOfJoin",
          align: "center",
          sortable: false,
        },
        {
          text: "Date of Resign",
          value: "dateOfResign",
          align: "center",
          sortable: false,
        },
      ],
      submenuName:null, 
    };
  },
  methods: {
    getData() {
      this.loadingState = true;
      this.$http
        .get(api.FORMER_HEADMATER)
        .then((res) => {
          this.data = res.data.data;
          this.submenuName = res.data.submenuName;
        })
        .catch((error) => {
          // alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
