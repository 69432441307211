<template>
  <v-row style="background-color: white" class="">
    <v-col cols="12" class="pa-0">
      <v-img width="100%" src="../../assets/footer.png"></v-img>
      <a
        href="https://www.dcampusbd.info/"
        target="_blank"
        style="text-decoration: none"
      >
        <div class="footerCopyright">
          Copyright &copy; {{ new Date().getFullYear() }} |
          <span class="ts_link" style="">
            Powered by Dcampus
          </span>
        </div>
      </a>
    </v-col>
  </v-row>
</template>

<script>
// import axios from "axios";
// import * as api from "../../config/api/frontendApi.js";
export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.footerCopyright {
  text-align: center;
  background-color: rgb(58, 57, 57);
  color: rgb(192, 192, 192);
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  letter-spacing: 1px;
}
.ts_link{
  color: #fff6f6; 
  cursor: pointer
}
.footerCopyright:hover .ts_link{
  color: #0093ad;
}
</style>
>
